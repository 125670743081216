import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/app/components/footer/footer.module.scss");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/app/components/goTop/goTop.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/app/components/header/display/Content.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/app/components/header/ui/flashMessage/FlashMessage.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/app/components/header/ui/LinkNav.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/app/globals.scss");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/app/components/header/header.module.scss");
;
import(/* webpackMode: "eager", webpackExports: ["Providers"] */ "/vercel/path0/src/app/redux/provider.tsx");
